import { createStore } from "redux";
import { services, workflows } from "./data";

const defaultState = {
    loaded: false,
    error: false,
    codeTab: 0,
    services: services,
    workflows: workflows
}


const rootReducer = (state = defaultState, action) => {

    switch (action.type) {

        case 'SET_DATA':
            return {
                ...state,
                loaded: true,
                services: action.services,
            }
        case 'SET_ERR':
            return {
                ...state,
                loaded: true,
                error: true,
            }

        case 'CHANGE_CODE_TAB':
            return {
                ...state,
                codeTab: action.payload,
            }

        default:
            return state
    }
};

export const store = createStore(rootReducer);