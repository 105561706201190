import React from 'react';
import { Box, Drawer, Toolbar, List, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function Sidebar(props) {

    const { sidebar } = props;
    const navigate = useNavigate()

    if (sidebar.display) {
        return <Box
            sx={{
                display: {
                    xs: 'none',
                    md: 'block'
                }
            }}
        >
            <Drawer
                variant="permanent"
                sx={{
                    width: sidebar.width,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: sidebar.width, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {
                            sidebar.items.map((item, index) => {
                                if (item.type === "divider") {
                                    return <Divider key={index} />
                                } else {
                                    return <ListItem
                                        button
                                        key={index}
                                        onClick={() => { if (!item.active) { navigate(sidebar.listType + item.path) } }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    align="left"
                                                    sx={{
                                                        fontWeight: item.active ? 'bold' : 'normal',
                                                        color: item.active ? 'primary.main' : 'text',
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                }
                            })
                        }
                    </List>
                </Box>
            </Drawer>
        </Box>
    } else {
        return <></>
    }

}