export const services = [
    {
        "title": "Intro",
        "description": [
            "We have made our multiple services publically available to anyone who wants to use them. You can use them in you own App/Website and start using them right away.",
            "This guide explains in detail how can you use them and how to get started. Currently, we have created public interface for gRPC and gRPC-Web. If you are unfamiliar with gRPC or gRPC-Web, we suggest you should get familiar with it first. This is out of scope for this guide.",
            "Every service has multiple functions that you can use. We have provided request-response structures and endpoints for sending them to make things easier for you. Also each request needs to have few headers. See the code along to get the idea."
        ],
        "note": {
            "title": "Note",
            "text": "Please note that all our services are in beta phase and there might be some breaking changes in near future.",
        },
        "name": "intro",
        "type": "link",
        "path": "/",
        "connectivity": [
            {
                "name": "gRPC",
                "url": "services.mazeyard.com:8443"
            },
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/"
            },
        ],
        "serviceCode": "// headers expected with each request\n{\n    // required for all except where mentioned\n    // auth token issued by Service66 during OAuth flow\n    \"authorization\": \"auth.token.goes.here\"\n\n    // optional\n    // device platform from which you are sending the request\n    \"platform\": \"Windows NT\",\n    // device user agent\n    \"useragent\": \"Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0\",\n}",
        "functions": []
    },
    {
        "title": "OAuth",
        "subtitle": "OAuth service provides functions relating to the OAuth flow.",
        "name": "oauth",
        "type": "link",
        "path": "/oauth",
        "connectivity": [
            {
                "name": "gRPC",
                "url": "services.mazeyard.com:8443"
            },
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/mazey.auth.AuthService"
            }
        ],
        "serviceCode": "syntax = \"proto3\";\n\npackage mazey.auth;\n\n// place the required messages here\n\nservice AuthService {\n    // place your functions here\n}",
        "functions": [
            {
                "name": "GetToken",
                "id": "get_token",
                "subtitle": "Get an auth token for the specified user.",
                "permissions": "None",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function is the first entrypoint for you into Mazeyard's services. It is used to get an auth token for the specified user.",
                    "Call this function immediately after the user is redirected to your app/website from Service66.",
                    "Set the request type to 'fresh' or 'refresh' depending on what your flow is. Pass the required values in the request.",
                    "This function returns an auth token (JWT or Session Token) along with user's basic details and a refresh token if your request type is 'fresh'."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GetTokenRequest {\n    string authCode = 1;\n    string authToken = 2;\n    string state = 3;\n    string requestType = 4;\n    string codeVerifier = 5;\n    string token = 6;\n    string refreshToken = 7;\n}\n\nmessage GetTokenResponse {\n    string token = 1;\n    string jti = 2;\n    string refreshToken = 3;\n    int64 validity = 4;\n    string firstName = 5;\n    string lastName = 6;\n    string userStatus = 7;\n    int64 userReference = 8;\n    string userRole = 9;\n}\n\nrpc GetToken (GetTokenRequest) returns (GetTokenResponse) {};"
                    }
                ]
            }
        ]
    },
    {
        "title": "Insti",
        "subtitle": "Insti service provides functions relating to all the institutes partnered with Mazeyard.",
        "name": "insti",
        "type": "link",
        "path": "/insti",
        "connectivity": [
            {
                "name": "gRPC",
                "url": "services.mazeyard.com:8443"
            },
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/mazey.insti.InstiService"
            }
        ],
        "serviceCode": "syntax = \"proto3\";\n\npackage mazey.insti;\n\n// place the required messages here\n\nservice InstiService {\n    // place your functions here\n}",
        "functions": [
            {
                "name": "GetInstiList",
                "id": "get_insti_list",
                "subtitle": "Get a list of all the institutes.",
                "permissions": "None",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function returns a list of all the institutes partnered with Mazeyard."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GetInstiListRequest {\n    string token = 1;\n}\n\nmessage EachInsti {\n    string Ieref = 1;\n    string InstiName = 2;\n}\n\nmessage GetInstiListResponse {\n    repeated EachInsti Institutes = 1;\n}\n\nrpc GetInstiList (GetInstiListRequest) returns (GetInstiListResponse) {};"
                    }
                ]
            },
            {
                "name": "GetInstiBasicInfo",
                "id": "get_insti_basic_info",
                "subtitle": "Get basic details of the specified institute.",
                "permissions": "None",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function returns basic details of the specified institute.",
                    "Pass the institute reference id as message in the request."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage GetInstiBasicInfoResponse {\n    string ieref = 1;\n    string name = 2;\n    string logo = 3;\n    string location = 4;\n}\n\nrpc GetInstiBasicInfo (GeneralRequest) returns (GetInstiBasicInfoResponse) {};"
                    }
                ]
            },
            {
                "name": "GetInstiInfo",
                "id": "get_insti_info",
                "subtitle": "Get details of the specified institute.",
                "permissions": "None",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function returns details of the specified institute.",
                    "Pass the institute reference id as message in the request."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage PrimProperty {\n    string eref = 1;\n    string name = 2;\n    string extId = 3;\n}\n\nmessage Institution {\n    string ieref = 1;\n    string name = 2;\n    string initials = 3;\n    string locality = 4;\n    string town = 5;\n    string city = 6;\n    string state = 7;\n    repeated PrimProperty streams = 8;\n    repeated PrimProperty fields = 9; \n}\n\nmessage GetInstiInfoResponse {\n    Institution institution = 1;\n}\n\nrpc GetInstiBasicInfo (GeneralRequest) returns (GetInstiBasicInfoResponse) {};"
                    }
                ]
            }
        ]
    },
    {
        "title": "Accounts",
        "subtitle": "Accounts service provides functions relating to the user's account.",
        "name": "accounts",
        "type": "link",
        "path": "/accounts",
        "connectivity": [
            {
                "name": "gRPC",
                "url": "services.mazeyard.com:8443"
            },
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/mazey.accounts.AccountsService"
            },
            // {
            //     "name": "HTTP",
            //     "url": "https://http-face.mazeyard.com/accounts"
            // }
        ],
        "serviceCode": "syntax = \"proto3\";\n\npackage mazey.accounts;\n\n// place the required messages here\n\nservice AccountsService {\n    // place your functions here\n}",
        "functions": [
            {
                "name": "GetUserBasic",
                "id": "Get_user_basic",
                "subtitle": "Get basic details of the specified user.",
                "permissions": "myAcc.accounts.getBasicProfile",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "When no request parameter is passed, this function will return the basic details of the user whose token is passed in the request header.",
                    "If you want to fetch the basic details of a specific user, pass the user reference id as message in the request.",
                    "This function returns only the first name, last name and reference id of the user."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n                        \nmessage GetUserBasicResponse {\n    string ueref = 1;\n    string firstName = 2;\n    string lastName = 3;\n}\n\nrpc GetUserBasic (GeneralRequest) returns (GetUserBasicResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /user\nrequest type: GET\ncontent-type: application/json\nurl params:\n    ueref: user reference id\n\n200 response:\n{\n    ueref: string\n    firstName: string\n    lastName: string\n}"
                    // }
                ]
            },
            {
                "name": "GetProfile",
                "id": "get_profile",
                "subtitle": "Get the profile details of the specified user.",
                "permissions": "myAcc.accounts.getPublicProfile / myAcc.accounts.getPrivateProfile",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function works two way. If you are calling it without any request parameters, it will return the details of the user whose token is passed in the request header.",
                    "To get private details of the user, you need the required permission for that.",
                    "If you want to fetch certain user's details, pass the user reference id as message in the request. If the user reference id is different than the one in auth token, the function will return only the public data of that user."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n                        \nmessage PrimProperty {\n    string eref = 1;\n    string name = 2;\n}\n                        \nmessage GetProfileResponse {\n    string message = 1;\n    string ueref = 2;\n    string firstName = 3;\n    string lastName = 4;\n    string email = 5;\n    string username = 6;\n    PrimProperty institution = 7;\n    PrimProperty stream = 8;\n    PrimProperty field  = 9;\n    string validity = 10;\n    string accountType = 11;\n    string about = 12;\n    string profilePic = 13;\n    string accountStatus = 14;\n}\n\nrpc GetProfile (GeneralRequest) returns (GetProfileResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /profile\nrequest type: GET\ncontent-type: application/json\nurl params:\n    ueref: user reference id\n\n200 response:\n{\n    message: string\n    ueref: string\n    firstName: string\n    lastName: string\n    email: string\n    username: string\n    institution: {\n        eref: string\n        name: string\n    }\n    stream: {\n        eref: string\n        name: string\n    }\n    field: {\n        eref: string\n        name: string\n    }\n    validity: string\n    accountType: string\n    about: string\n    profilePic: string\n}"
                    // }
                ]
            },
            {
                "name": "GetContactDetails",
                "id": "get_contact_details",
                "subtitle": "Get the contact details of user.",
                "permissions": "myAcc.accounts.getContactDetails",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function does not require any paramters as it is intended to return the contact details of only that user whose token is passed in the request header.",
                    "This function returns various contact details the user has added to his/her profile including emaill, social media links, etc."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n                        \nmessage ContactDetails {\n    string contactType = 1;\n    string contactValue = 2;\n    bool isPublic = 3;\n}\n                        \nmessage GetContactDetailsResponse {\n    repeated ContactDetails contacts = 1;\n}\n\nrpc GetContactDetails (GeneralRequest) returns (GetContactDetailsResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /user/contact_details\nrequest type: GET\ncontent-type: application/json\nurl params:\n    ueref: user reference id\n\n200 response:\n{\n    contacts: [\n        {\n            contactType: string\n            contactValue: string\n            isPublic: bool\n        }\n    ]\n}"
                    // }
                ]
            },
            {
                "name": "SetContactDetails",
                "id": "set_contact_details",
                "subtitle": "Set the contact details of user.",
                "permissions": "myAcc.accounts.setContactDetails",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function does not require any paramters as it is intended to modify the contact details of only that user whose token is passed in the request header.",
                    "This function needs to be called for every contact type that needs to be modified.",
                    "Currently supported contact types are 'email', 'facebook', 'twitter', 'instagram' and 'linkedin'. It will return error if any other type is passed."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message SetContactDetailsRequest {\n    string contactType = 1;\n    string contactValue = 2;\n    bool isPublic = 3;\n}\n                        \nmessage GeneralResponse {\n    string message  = 1;\n}\n\nrpc SetContactDetails (SetContactDetailsRequest) returns (GeneralResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /user/contact_details\nrequest type: POST\ncontent-type: application/json\n\nrequest:\n{\n    contactType: string\n    contactValue: string\n    isPublic: bool\n}\n\n200 response:\n{\n    message: string\n}"
                    // }
                ]
            },
            {
                "name": "SetAbout",
                "id": "set_about",
                "subtitle": "Set the about section of the user.",
                "permissions": "myAcc.accounts.setAbout",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function is intended to modify the about section of only that user whose token is passed in the request header.",
                    "Pass the about section as message in the request.",
                    "Max length allowed is 140 characters."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n                        \nmessage GeneralResponse {\n    string message  = 1;\n}\n\nrpc SetAbout (GeneralRequest) returns (GeneralResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /user/about\nrequest type: POST\ncontent-type: application/json\n\nrequest:\n{\n    message: string\n}\n\n200 response:\n{\n    message: string\n}"
                    // }
                ]
            },
            {
                "name": "ChangeUserProperty",
                "id": "change_user_property",
                "subtitle": "Change certain properties of the user.",
                "permissions": "None",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function is intended to modify certain properties of only that user whose token is passed in the request header.",
                    "Currently supported property is 'profilePicture'. Pass the url of the profile picture as message in the request. (Please note that the the image must be hosted on the Mazeyard's Storage Service.",
                    "This function was included in the initial prototype and is planned for deprecation in the future. Replacement function will be updated in the docs once it is available in the backend."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message ChangeUserPropertyRequest {\n    string property = 1;\n    string value = 2;\n}\n                        \nmessage GeneralResponse {\n    string message  = 1;\n}\n\nrpc ChangeUserProperty (ChangeUserPropertyRequest) returns (GeneralResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /user/property\nrequest type: POST\ncontent-type: application/json\n\nrequest:\n{\n    property: string\n    value: string\n}\n\n200 response:\n{\n    message: string\n}"
                    // }
                ]
            }
        ]
    },
    {
        "title": "Storage",
        "subtitle": "Storage service provides functions relating to the storage quota of the user.",
        "name": "storage",
        "type": "link",
        "path": "/storage",
        "connectivity": [
            {
                "name": "gRPC",
                "url": "services.mazeyard.com:8443"
            },
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/mazey.storage.StorageService"
            },
            // {
            //     "name": "HTTP",
            //     "url": "https://http-face.mazeyard.com/storage"
            // }
        ],
        "serviceCode": "syntax = \"proto3\";\n\npackage mazey.storage;\n\n// place the required messages here\n\nservice StorageService {\n    // place your functions here\n}",
        "functions": [
            {
                "name": "ListEntities",
                "id": "list_entities",
                "subtitle": "List all the entities in the directory.",
                "permissions": "mazey.storage.listEntities",
                "permissionParameters": [
                    "Yes",
                    "Altleast the directory name is required.",
                    "Wildcard (*) also works here."
                ],
                "description": [
                    "This function returns a list of all the entities listed under the specified directory."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage Entity {\n    string name = 1;\n    string type = 2;\n    string createdAt = 3;\n    string lastModifiedAt = 4;\n    string owner = 5;\n    string address = 6;\n    string parentDirectory = 7;\n    string size = 8;\n    string category = 9;\n    string uploaderApp = 10;\n    string description = 11;\n    string visibility = 12;\n    repeated string sharedWith = 13;\n}\n\nmessage ListEntitiesResponse {\n    Entity directory = 1;\n    repeated Entity entities = 2;\n}\n\nrpc ListEntities (GeneralRequest) returns (ListEntitiesResponse) {};"
                    },
                    // {
                    //     "type": "HTTP",
                    //     "code": "path: /\nrequest type: GET\ncontent-type: application/json\nurl params:\n    directory: directory to look into\n\n200 response:\n{\n    directory: {\n    }\n}"
                    // }
                ]
            },
            {
                "name": "CreateEntity",
                "id": "create_entity",
                "subtitle": "Create new entity under the specified directory.",
                "permissions": "mazey.storage.createEntity",
                "permissionParameters": [
                    "Yes",
                    "Altleast the parent directory name is required.",
                    "Wildcard (*) also works here."
                ],
                "description": [
                    "This function creates an entity under the specified directory.",
                    "Pass the bare minimum details of the entity in the request.",
                    "Set 'forceDirectoryCreation' to 'true' if you want to force create the non existing directory.",
                    "Please note that you can only create folder through this function. To upload a file, refer to the FileService."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message Entity {\n    string name = 1;\n    string type = 2;\n    string createdAt = 3;\n    string lastModifiedAt = 4;\n    string owner = 5;\n    string address = 6;\n    string parentDirectory = 7;\n    string size = 8;\n    string category = 9;\n    string uploaderApp = 10;\n    string description = 11;\n    string visibility = 12;\n    repeated string sharedWith = 13;\n}\n\nmessage CreateEntityRequest {\n    Entity file = 1;\n    string fileHash = 2;\n    bool forceDirectoryCreation = 3;\n}\n\nmessage GeneralResponse {\n    string message = 1;\n}\n\nrpc CreateEntity (CreateEntityRequest) returns (GeneralResponse) {};"
                    }
                ]
            },
            {
                "name": "DeleteEntity",
                "id": "delete_entity",
                "subtitle": "Delete the specified entity.",
                "permissions": "mazey.storage.deleteEntity",
                "permissionParameters": [
                    "Yes",
                    "Altleast the parent directory name is required.",
                    "Wildcard (*) also works here."
                ],
                "description": [
                    "This function deletes the entity whose address is specified in the request.",
                    "This is a non-reversible action as it will delete the file permanently. There is no concept of Trash or Recycle Bin in this service.",
                    "Set 'forceDeletion' to 'true' to delete the entity forcefully and immediately. This might incur some side effects. Use it only if it is really required."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message DeleteEntityRequest {\n    string fileAddress = 1;\n    bool forceDeletion = 2;\n}\n\nmessage GeneralResponse {\n    string message = 1;\n}\n\nrpc DeleteEntity (DeleteEntityRequest) returns (GeneralResponse) {};"
                    }
                ]
            },
            {
                "name": "UpdateEntity",
                "id": "update_entity",
                "subtitle": "Update the details of the specified entity.",
                "permissions": "mazey.storage.updateEntity",
                "permissionParameters": [
                    "Yes",
                    "Altleast the parent directory name is required.",
                    "Wildcard (*) also works here."
                ],
                "description": [
                    "This function is currently unimplemented and hence put on hold for thorough documentation."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage GeneralResponse {\n    string message = 1;\n}\n\nrpc UpdateEntity (GeneralRequest) returns (GeneralResponse) {};"
                    }
                ]
            },
            {
                "name": "GetFileMetadata",
                "id": "get_file_metadata",
                "subtitle": "Get the metadata of the specified entity.",
                "permissions": "mazey.fileService.getFileMetadata",
                "permissionParameters": [
                    "Yes",
                    "Altleast the parent directory name is required.",
                    "Wildcard (*) also works here."
                ],
                "description": [
                    "This function returns the details of the specified entity.",
                    "Pass the address of the entity as the message in the request."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage Entity {\n    string name = 1;\n    string type = 2;\n    string createdAt = 3;\n    string lastModifiedAt = 4;\n    string owner = 5;\n    string address = 6;\n    string parentDirectory = 7;\n    string size = 8;\n    string category = 9;\n    string uploaderApp = 10;\n    string description = 11;\n    string visibility = 12;\n    repeated string sharedWith = 13;\n}\n\nmessage GetFileMetadataResponse {\n    Entity file = 1;\n}\n\nrpc UpdateEntity (GeneralRequest) returns (GeneralResponse) {};"
                    }
                ]
            },
            {
                "name": "GetActivities",
                "id": "get_activities",
                "subtitle": "Get the user's activities",
                "permissions": "mazey.storageStats.getActivities",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function returns the activities related to their usage of the storage service.",
                    "Pass the 'activityType' in the request to get the list of activities of that type only. You can get the idea of supported types by passing it as blank.",
                    "Set 'lastActivityTime' to filter out only the recent activities from the given time only. The value must of time format of RFC3339 standard. Set it blank to get all the activities."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GetActivitiesRequest {\n    string activityType = 1;\n    string lastActivityTime = 2;\n}\n\nmessage Activity {\n    string activityTime = 1;\n    string type = 2;\n    string primaryData = 3;\n    string secondaryData = 4;\n    string tertiaryData = 5;\n}\n\nmessage GetActivitiesResponse {\n    repeated Activity activities = 1;\n}\n\nrpc GetActivities (GetActivitiesRequest) returns (GetActivitiesResponse) {};"
                    }
                ]
            },
            {
                "name": "GetStorageStats",
                "id": "get_storage_stats",
                "subtitle": "Get the statistics of the user's storage quota.",
                "permissions": "mazey.storageStats.getStorageStats",
                "permissionParameters": [
                    "None"
                ],
                "description": [
                    "This function returns statistics about the user's storage quota.",
                    "It includes the amount of storage allocated, currently in use, and remaining amount to the user's account",
                    "The response also includes the amount of storage used by files in different categories like documents, videos, audios, etc. and the correspoding percentage calculations",
                    "The key is the name of the category and value is the storage in use under that category."
                ],
                "requestCodes": [
                    {
                        "type": "gRPC",
                        "code": "message GeneralRequest {\n    string message = 1;\n}\n\nmessage KeyValue {\n    string key = 1;\n    string value = 2;\n}\n\nmessage GetStorageStatsResponse {\n    repeated KeyValue values = 1;\n}\n\nrpc GetStorageStats (GeneralRequest) returns (GetStorageStatsResponse) {};"
                    }
                ]
            }
        ]
    }
]

export const workflows = [
    {
        "title": "Intro",
        "description": [
            "We have made our multiple services publically available to anyone who wants to use them. You can use them in you own App/Website and start using them right away.",
            "This guide explains in detail how can you use them and how to get started. Currently, we have created public interface for gRPC-Web only. This means you are limited to only one option. But we have already planned for expansion into other methods of connectivity as well, and will roll them out as soon as we can. If you are unfamiliar with gRPC or gRPC-Web, we suggest you should get familiar with it first. This is out of scope for this guide.",
            "Every service has multiple functions that you can use. We have provided request-response structures and endpoints for sending them to make things easier for you. Also each request needs to have few headers. See the code along to get the idea."
        ],
        "note": {
            "title": "Note",
            "text": "Please note that all our services are in beta phase and there might be some breaking changes in near future.",
        },
        "name": "intro",
        "type": "link",
        "path": "/",
        "connectivity": [
            {
                "name": "gRPC-Web",
                "url": "https://services.mazeyard.com/"
            }
        ],
        "serviceCode": "// headers expected with each request\n{\n    // required\n    // auth token issued by Service66 during OAuth flow\n    \"authorization\": \"gibberish.characters.goes.here\"\n\n    // optional\n    // device platform from which you are sending the request\n    \"platform\": \"Windows NT\",\n    // device user agent\n    \"useragent\": \"Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0\",\n}",
        "functions": []
    },
    {
        "title": "Intro",
        "description": [
            "We have made our multiple services publically available to anyone who wants to use them. You can use them in you own App/Website and start using them right away.",
            "This guide explains in detail how can you use them and how to get started. Currently, we have created public interface for gRPC-Web only. This means you are limited to only one option. But we have already planned for expansion into other methods of connectivity as well, and will roll them out as soon as we can. If you are unfamiliar with gRPC or gRPC-Web, we suggest you should get familiar with it first. This is out of scope for this guide.",
            "Every service has multiple functions that you can use. We have provided request-response structures and endpoints for sending them to make things easier for you. Also each request needs to have few headers. See the code along to get the idea."
        ],
        "note": {
            "title": "Note",
            "text": "Please note that all our services are in beta phase and there might be some breaking changes in near future.",
        },
        "name": "intro",
        "type": "link",
        "path": "/",
    },
]