import React from 'react'
import { IconButton, Box, Grid, Toolbar, AppBar, Typography, CssBaseline, useScrollTrigger } from '@mui/material'
import { useNavigate } from 'react-router'
import MenuIcon from '@mui/icons-material/Menu';

export default function Topbar(props) {

    const navigate = useNavigate()
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    })

    return <>
        <CssBaseline />
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            elevation={trigger ? 4 : 0}
        >
            <Toolbar>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ flexGrow: 1, cursor: 'pointer', display: { xs: 'none', md: 'flex' } }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        onClick={() => navigate('/')}
                    >
                        MazeYard Docs {props.sectionName ? " - " : ""}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mt: '3px', ml: 1 }}
                    >
                        {props.sectionName}
                    </Typography>
                </Grid>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => props.setDrawerOpen(!props.drawerOpen)}
                        color="inherit"
                    >
                        <MenuIcon
                            sx={{ color: props.displaySidebar ? 'white' : 'primary.main' }}
                        />
                    </IconButton>
                </Box>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        mr: 5,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => navigate('/')}
                >
                    MazeYard Docs {props.sectionName ? " - " + props.sectionName : ""}
                </Typography>
            </Toolbar>
        </AppBar>
        <Toolbar />
    </>
}