import React from 'react'
import { Box, Typography, Grid, Button, Tab, Tabs, Link, AlertTitle, Alert } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import RenderCode from './renderCode'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EachService(props) {

    const { service } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tabValue = useSelector(state => state.codeTab)

    const handleTabChange = (event, newValue) => {
        dispatch({ type: 'CHANGE_CODE_TAB', payload: newValue })
    };

    const renderFunctions = () => {
        if (service.functions && service.functions.length > 0) {
            return service.functions.map((eachFunction) => {
                return <Grid container sx={{ padding: '20px 0' }} spacing={0} key={eachFunction.id}>
                    <Grid item xs={12} md={6} sx={{ paddingRight: '10px' }}>
                        <Typography
                            id={eachFunction.id}
                            variant="h3"
                            sx={{ color: 'primary.main', fontSize: '28px', margin: '10px 0 10px 0', textDecoration: 'underline' }}
                        >
                            {eachFunction.name}
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '18px', marginBottom: '20px' }}>
                            {eachFunction.subtitle}
                            <br />
                        </Typography>
                        {eachFunction.permissions && <Typography variant='body1' sx={{ fontSize: '16px', marginBottom: '10px' }}>
                            <strong>Permission Required : </strong>
                            <br />
                            {eachFunction.permissions}
                        </Typography>}
                        {eachFunction.permissionParameters.length > 0 && <Typography variant='body1' sx={{ fontSize: '16px', marginBottom: '10px' }}>
                            <strong>Permission Parameters Required : </strong>
                            <br />
                            {
                                eachFunction.permissionParameters.map(eachParam => {
                                    return <span key={eachParam.id}>{eachParam}<br /></span>
                                })
                            }
                        </Typography>}
                        <Typography variant='body1' sx={{ fontSize: '16px', marginBottom: '10px' }}>
                            <strong>Description : </strong>
                            {
                                eachFunction.description.map((eachD, index) => {
                                    return <span key={index}>{eachD}<br /></span>
                                })
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="div"
                            sx={{
                                bgcolor: '#001E3C', margin: '5px 0 20px 0', padding: '0px',
                                color: 'grey.300', border: '1px solid',
                                borderRadius: 0
                            }}
                        >
                            <Tabs
                                value={tabValue} onChange={handleTabChange}
                                aria-label="basic tabs example" centered
                            >
                                {
                                    eachFunction.requestCodes.map((reqCode, index) => {
                                        return <Tab label={reqCode.type} {...a11yProps(index)} key={index} />
                                    })
                                }
                            </Tabs>
                            {
                                eachFunction.requestCodes.map((reqCode, index) => {
                                    return <TabPanel value={tabValue} index={index} key={index}>
                                        <Box sx={{ overflowX: 'auto' }}>
                                            <RenderCode code={reqCode.code} />
                                        </Box>
                                    </TabPanel>
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            })
        }
        return <></>
    }

    const renderFunctionsList = () => {
        if (service.functions && service.functions.length > 0) {
            return <>
                <Typography variant="h5" gutterBottom>
                    Functions:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '16px', margin: '10px 0 40px 30px', }}>
                    {service.functions.map((eachFunction) => {
                        return <Link href={`#${eachFunction.id}`} key={eachFunction.id}>
                            {eachFunction.name}
                            <br />
                        </Link>
                    })}
                </Typography>
            </>
        }
    }

    const renderServiceDescription = () => {
        if (service.description && service.description.length > 0) {
            return <Box sx={{ margin: '20px 0' }}>
                {
                    service.description.map((eachD, index) => {
                        return <Typography variant="body1" gutterBottom key={index}>
                            {eachD}
                        </Typography>
                    })
                }
            </Box>
        }
    }

    const renderServiceNote = () => {
        if (service.note) {
            return <Alert severity="info" sx={{ marginBottom: '30px' }}>
                <AlertTitle><strong>{service.note.title}</strong></AlertTitle>
                {service.note.text}
            </Alert>
        }
        return <></>
    }

    const renderService = () => {
        if (service) {
            return <Box >
                <Typography variant="h3" color="textPrimary">
                    {service.title}
                </Typography>
                {service.subtitle && <Typography variant="h6" >
                    {service.subtitle}
                </Typography>}
                {renderServiceDescription()}
                {renderServiceNote()}
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '17px',
                        margin: '10px 0 30px 0',
                        bgcolor: 'text.disabled',
                        padding: '5px'
                    }}
                >
                    {
                        service.connectivity.map((eachConn) => {
                            return <span key={eachConn.name}>
                                <strong>{eachConn.name} : </strong>{eachConn.url}
                                <br />
                            </span>
                        })
                    }
                </Typography>
                {renderFunctionsList()}
                {service.serviceCode && <Box
                    component="div"
                    sx={{
                        bgcolor: '#001E3C', margin: '5px 0 20px 0', padding: '20px',
                        color: 'grey.300', border: '1px solid',
                        borderRadius: 0, overflowX: 'auto'
                    }}
                >
                    <RenderCode code={service.serviceCode} />
                </Box>
                }
                {renderFunctions()}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginTop: '50px', marginBottom: '20px' }}
                >
                    <Button
                        sx={{ textTransform: 'none' }}
                        startIcon={<NavigateBefore />}
                        disabled={props.leftButton.disabled}
                        onClick={() => navigate(`/services${props.leftButton.link}`)}
                    >
                        Back
                    </Button>
                    <Button
                        sx={{ textTransform: 'none' }}
                        endIcon={<NavigateNext />}
                        disabled={props.rightButton.disabled}
                        onClick={() => navigate(`/services${props.rightButton.link}`)}
                    >
                        Next
                    </Button>
                </Grid>
            </Box>
        }
    }

    return <Box>
        {renderService()}
    </Box>
}