import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function Homepage(props) {
    const navigate = useNavigate()

    const sidebar = {
        display: false,
    }

    const sections = [
        {
            title: "Services",
            clickHandler: () => navigate("/services")
        },
        // {
        //     title: "Workflows",
        //     clickHandler: () => navigate("/workflows")
        // },
        {
            title: "Contact Us",
            clickHandler: () => window.open('https://www.mazeyard.com/contact', '_blank', 'noopener,noreferrer'),
        },
    ]

    useEffect(() => {
        props.setSidebar(sidebar)
        props.setTopbarSectionName("")
    }, [])

    return <Box
        sx={{
            width: '100%',
            height: `calc(100vh - 70px)`,
        }}
    >
        <Grid container
            sx={{ height: '100%' }}
        >
            {
                sections.map((eachService) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        key={eachService.title}
                        sx={{ height: '50%' }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                                color: 'text.primary',
                                textTransform: 'none',
                            }}
                            component={Button}
                            onClick={() => eachService.clickHandler()}
                        >
                            <Box
                                sx={{
                                    border: '1px solid black',
                                    borderRadius: '5px',
                                    padding: '2px'
                                }}
                            >
                                <Box
                                    sx={{
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                        padding: '10px 20px'
                                    }}
                                >
                                    <Typography variant="h4">
                                        {eachService.title}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ))
            }

        </Grid>
    </Box>
}