import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Homepage from './homepage'
import Services from './services'
import Topbar from './topbar'
import Sidebar from './sidebar'
import AppDrawer from './app_drawer'
// import Workflows from './workflows'

export default function AppRoutes() {

    const [sidebar, setSidebar] = useState({
        display: false,
        items: []
    })
    const [topbarSectionName, setTopbarSectionName] = useState("")
    const [drawerOpen, setDrawerOpen] = useState(false)

    useEffect(() => {
        setTopbarSectionName("")
    }, [])


    return <BrowserRouter>
        <Sidebar sidebar={sidebar} />
        <Topbar
            displaySidebar={sidebar.display}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            sectionName={topbarSectionName}
        />
        <AppDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            drawerContent={sidebar}
        />
        <Routes>
            <Route
                path="/"
                element={
                    <Homepage
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                        setTopbarSectionName={setTopbarSectionName}
                    />
                }
            />
            <Route
                path="/services"
                element={
                    <Services
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                        setTopbarSectionName={setTopbarSectionName}
                    />
                }
            />
            <Route
                path="/services/:serviceName"
                element={
                    <Services
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                        setTopbarSectionName={setTopbarSectionName}
                    />
                }
            />
            {/* <Route
                path="/workflows"
                element={
                    <Workflows
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                        setTopbarSectionName={setTopbarSectionName}
                    />
                }
            /> */}
        </Routes>
    </BrowserRouter>
}