import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import EachService from './eachService'
import { useParams } from 'react-router'

export default function Services(props) {

    const setTopbarSectionName = props.setTopbarSectionName
    const setSidebar = props.setSidebar

    const services = useSelector(state => state.services)
    const { serviceName } = useParams()
    const [toRenderService, setToRenderService] = useState(null)
    const [leftButton, setLeftButton] = useState({
        disabled: true,
        link: '',
    })
    const [rightButton, setRightButton] = useState({
        disabled: true,
        link: '',
    })


    const sidebar = {
        display: true,
        items: services,
        width: 240,
        listType: '/services'
    }

    useEffect(() => {
        setTopbarSectionName("Services")
        if (services.length === 0) {
            sidebar.items = services
            sidebar.display = false
            setSidebar(sidebar)
            return
        }
        for (let i = 0; i < services.length; i++) {
            services[i].active = false
        }
        services[0].active = true
        setLeftButton({
            disabled: true,
            link: '',
        })
        setRightButton({
            disabled: false,
            link: services[1].path,
        })
        for (let i = 0; i < services.length; i++) {
            if (services[i].name === serviceName) {
                setToRenderService(services[i])
                services[0].active = false
                services[i].active = true
                if (i === 0) {
                    setLeftButton({
                        disabled: true,
                        link: '',
                    })
                    setRightButton({
                        disabled: false,
                        link: services[i + 1].path,
                    })
                } else if (i === (services.length - 1)) {
                    setLeftButton({
                        disabled: false,
                        link: services[i - 1].path,
                    })
                    setRightButton({
                        disabled: true,
                        link: '',
                    })
                } else {
                    setLeftButton({
                        disabled: false,
                        link: services[i - 1].path,
                    })
                    setRightButton({
                        disabled: false,
                        link: services[i + 1].path,
                    })
                }
            }
        }
        sidebar.items = services
        setSidebar(sidebar)
    }, [serviceName, services])

    const renderServices = () => {
        if (services === undefined) {
            return <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h6" color="error">
                    Something went wrong
                </Typography>
            </Grid>
        }
        if (serviceName === null || serviceName === undefined || serviceName === "") {
            return <EachService
                service={services[0]}
                leftButton={leftButton}
                rightButton={rightButton}
            />
        } else {
            return <EachService
                service={toRenderService}
                leftButton={leftButton}
                rightButton={rightButton}
            />
        }
    }

    return <Box
        sx={{
            width: {
                xs: '100%',
                md: props.sidebar.display ? `calc(100% - ${props.sidebar.width}px)` : '100%',
            },
            marginLeft: {
                xs: 0,
                md: props.sidebar.display ? `${props.sidebar.width}px` : 0,
            },
            textAlign: 'left',
            padding: '30px'
        }}
    >
        {renderServices()}
    </Box>
}