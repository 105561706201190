import React from 'react'
import { Box, Drawer, Toolbar, List, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function AppDrawer(props) {
    const navigate = useNavigate()
    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.setDrawerOpen(false)
    }

    if (!props.drawerContent.display) {
        return <></>
    }

    return <Drawer
        anchor="left"
        open={props.drawerOpen}
        onClose={toggleDrawer}
    >
        <Box sx={{ overflow: 'auto', minWidth: 200 }}>
            <Toolbar />
            <List>
                {
                    props.drawerContent.items.map((item, index) => {
                        if (item.type === "divider") {
                            return <Divider key={index} />
                        } else {
                            return <ListItem
                                button
                                key={index}
                                onClick={() => { if (!item.active) { navigate(props.drawerContent.listType + item.path) } }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            align="left"
                                            sx={{
                                                fontWeight: item.active ? 'bold' : 'normal',
                                                color: item.active ? 'primary.main' : 'text',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        }
                    })
                }
            </List>
        </Box>
    </Drawer>
}